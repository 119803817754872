export enum SearchableField {
  name = "name",
  email = "email",
  phone = "phone",
  place_of_employment = "place_of_employment",
  address = "address",
}

export enum UserRole {
  admin = "admin",
  user = "user",
  practitioner = "practitioner",
}

export enum UserStatus {
  active = "active",
  inactive = "inactive",
  pending = "pending",
}

export interface IUserProfile {
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  requires_course: boolean;
  first_name: string;
  last_name: string;
  place_of_employment: string;
  role: UserRole;
  status: UserStatus;
  province: string;
  town: string;
  country: string;
  is_searchable: boolean;
  search_fields: SearchableField[];
  phone: string;
  avatar_url?: string;
  id: number;
  practitioner_number?: string;
}

export interface IUsersWrapper {
  users: IUserProfile[];
  pagination: IPagination;
}

export interface IUserProfileUpdate {
  email?: string;
  phone?: string;
  province?: string;
  town?: string;
  country?: string;
  place_of_employment?: string;
  password?: string;
}

export interface IUserProfileAdminUpdate extends IUserProfileUpdate {
  first_name?: string;
  last_name?: string;
  role?: UserRole;
  status?: UserStatus;
}

export interface IUserProfileCardUpdate {
  is_searchable?: boolean;
  search_fields?: SearchableField[];
}

export interface INameChangeRequestCreate {
  new_first_name: string;
  new_last_name: string;
}

export interface IUserProfileCreate {
  email: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  province?: string;
  town?: string;
  country?: string;
  place_of_employment?: string;
  password?: string;
  role?: UserRole;
  status?: UserStatus;
}

export interface IUserSignUp {
  email: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  province?: string;
  town?: string;
  country?: string;
  place_of_employment?: string;
  password?: string;
  nda_signature?: string;
}

export interface IUserAttendee {
  email: string;
  first_name?: string;
  last_name?: string;
}

export interface IDirectoryEntry {
  user_id?: number;
  name?: string;
  email?: string;
  phone?: string;
  place_of_employment?: string;
  address?: string;
  avatar_url?: string;
}

export interface IChangeRequest {
  id: number;
  user_id?: number;
  full_name: string;
  new_data: string;
  status: string;
  creation_date: string;
}

export interface IChangeRequestsWrapper {
  pagination: IPagination;
  change_requests: IChangeRequest[];
}

export interface IPagination {
  total: number;
}

export interface IEvent {
  id: number;
  title: string;
  status: string;
  venue?: string;
  start_date: string;
  end_date: string;
  time_zone: string;
  utc_offset: string;
  description_html: string;
  price?: number;
  eventzilla_id: string;
  bgimage_url: string;
}

export interface IEventAdmin extends IEvent {
  post_test_code: string;
}

export interface IEventUpdate {
  post_test_code?: string;
}

export interface IEventsWrapper {
  pagination: IPagination;
  events: IEvent[];
}

export interface IMaterialsDownload {
  id: number;
  download_count: number | null;
  consumed_count: number | null;
  effective_date: string | null;
  expiration_date: string | null;
  last_consumed_date: string | null;
}

export interface ICanPurchaseMaterialsDownload {
  can_purchase: boolean;
}

export interface IEnrollmentBase {
  id: number;
  test_completed_date: string;
  attended: boolean;
  thinkific_enrollment_id?: number;
}

export interface IEnrollment extends IEnrollmentBase {
  event_id: number;
  event: IEvent;
}

export interface IEnrollmentAttendee extends IEnrollmentBase {
  user_id: number;
  user: IUserAttendee;
  has_download_available: boolean;
  last_downloaded_materials_on: string | null;
  has_download_override: boolean;
}

export interface IThinkificSSOUrl {
  url: string;
}

// Checkout
export interface ITicketType {
  ticket_type_id: string;
  ticket_type_name: string;
  ticket_type_price: number;
  ticket_type_min_limit: number;
  ticket_type_max_limt: number;
  ticket_type_avail_quantity: number;
  ticket_type_onsale: boolean;
  ticket_type_description: string;
}

export interface IQuestion {
  question_id: string;
  question_type: string;
  question_text: string;
  question_choices: string[];
}

export interface IQuestionRule {
  id: number;
  mapped_choice: string[];
  question_id: number;
  rule_key: string;
  subquestion_id: number;
}

export interface IQuestionDetail {
  id: number;
  question_type: string;
  question_text: string;
  question_choice: string[];
  is_primary: boolean;
  subquestion_rules: IQuestionRule[];
}

export interface ITax {
  tax_type: string;
  tax_name: string;
  tax_value: string;
}

export interface IBaseCheckoutPrep {
  payment_options: { payment_id: string; paymentoption_name: string }[];
  tickettypes: ITicketType[];
  questions: IQuestion[];
  discount_enabled: boolean;
  tax_enabled: boolean;
  tax: ITax[];
}

export interface ICheckoutPrep extends IBaseCheckoutPrep {
  event_id: number;
}

export interface ICheckoutSessionCreate {
  event_id: number;
  ticket_type_id: string;
  discount_code: string;
}

export interface ICheckoutSession {
  id: number;
  event_id: number;
  status: string;
  amount: number;
  fee: number;
  quantity: number;
  ticket_type_name: string;
  stripe_checkout_session_id: string | null;
}

export interface ICheckoutSessionWithEvent extends ICheckoutSession {
  event?: IEvent;
  material_download: boolean;
  creation_date: string;
}

export interface IAnswer {
  question_id: string;
  answer_text: string;
}

export interface ICheckoutFillOrder {
  payment_id: string;
  answers: IAnswer[];
}

// CMS

export interface CMSMeta {
  total_count: number;
}

export interface Wrapper<T> {
  meta: CMSMeta;
  items: T[];
}

export interface IEvidence {
  id: number;
  evidence_title: string;
  description: string;
  link?: string;
}

export interface IAssociatedEvidence {
  id: number;
  evidence_title: string;
}

export interface IPolicyDocument {
  body: string;
}

export interface IProduct {
  id: number;
  price: number;
  display_name: string;
  last_modified: string;
}

export interface IProductUpdate {
  price: number;
}

export interface IBlog {
  id: number;
  title: string;
  intro: string;
  date: string;
  hero_image: { title: string; url: string } | null;
}

export interface IBlogBlock {
  id: string;
  type: string;
  value: number | string | { title: string; url: string };
}

export interface IBlogDetails extends IBlog {
  body: IBlogBlock[];
}

export interface IResource {
  id: number;
  title: string;
  date: string;
  description: string;
  resource_file: { meta: { download_url: string }; title: string };
}

// Certificate

export interface IUserCertificate {
  first_name?: string;
  last_name?: string;
}

export interface ICertificate {
  id: number;
  certificate_type?: string;
  effective_date?: string;
  expiration_date?: string;
  user: IUserCertificate;
  version?: number;
}

export interface ICertificatesWrapper {
  pagination: IPagination;
  certificates: ICertificate[];
}

export interface ICertificateUpdate {
  certificate_type?: string;
  effective_date?: string;
  expiration_date?: string;
}

export interface IHostSeminar {
  name: string;
  email: string;
  location: string;
}

export interface IThinkificProduct {
  id: number;
  price: number;
  status: string;
  name: string;
  private: boolean;
  hidden: boolean;
  description: string;
  card_image_url: string;
}
