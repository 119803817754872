
  import { Component, Vue } from "vue-property-decorator";
  import { IUserProfileCreate, UserRole, UserStatus } from "@/interfaces";
  import { dispatchGetUsers, dispatchCreateUser } from "@/store/admin/actions";
  import { required, confirmed, email } from "vee-validate/dist/rules";
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
  import countries from "@/countries";

  // register validation rules
  extend("required", { ...required, message: "{_field_} can not be empty" });
  extend("confirmed", { ...confirmed, message: "Passwords do not match" });
  extend("email", { ...email, message: "Invalid email address" });

  @Component({
    components: {
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class CreateUser extends Vue {
    $refs!: {
      observer: InstanceType<typeof ValidationObserver>;
    };

    countries = countries;
    roles = Object.keys(UserRole);
    statuses = Object.keys(UserStatus);

    public valid = false;
    public firstName = "";
    public lastName = "";
    public email = "";
    public phone = "";
    public placeOfEmployment = "";
    public town = "";
    public province = "";
    public country = "";
    public status = UserStatus.active;
    public role = UserRole.user;
    public setPassword = false;
    public password1 = "";
    public password2 = "";

    public async mounted() {
      await dispatchGetUsers(this.$store);
      this.onReset();
    }

    public onReset() {
      this.password1 = "";
      this.password2 = "";
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.phone = "";
      this.placeOfEmployment = "";
      this.town = "";
      this.province = "";
      this.country = "";
      this.status = UserStatus.active;
      this.role = UserRole.user;
      this.$refs.observer.reset();
    }

    public cancel() {
      this.$router.back();
    }

    public async onSubmit() {
      const success = await this.$refs.observer.validate();

      if (!success) {
        return;
      }

      const updatedProfile: IUserProfileCreate = {
        email: this.email,
      };

      if (this.firstName) {
        updatedProfile.first_name = this.firstName;
      }
      if (this.lastName) {
        updatedProfile.last_name = this.lastName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      if (this.phone) {
        updatedProfile.phone = this.phone;
      }
      if (this.town) {
        updatedProfile.town = this.town;
      }
      if (this.province) {
        updatedProfile.province = this.province;
      }
      if (this.country) {
        updatedProfile.country = this.country;
      }
      if (this.placeOfEmployment) {
        updatedProfile.place_of_employment = this.placeOfEmployment;
      }
      updatedProfile.role = this.role;
      updatedProfile.status = this.status;
      updatedProfile.password = this.password1;
      await dispatchCreateUser(this.$store, updatedProfile);
      this.$router.push("/main/admin/users");
    }
  }
